import fastDeepEqual from "fast-deep-equal"
import { findIndex, propEq } from "ramda"
import { isArrowImagePlan } from "js/includes/common/backup"

export const onAddBackupPlan = (state, { plan }) => ({
  ...state,
  backupPlans: [...state.backupPlans, plan],
})

export const onUpdateBackupPlan = (state, { plan }) => {
  const backupPlans = [...state.backupPlans]
  const planIndex = findIndex(propEq("identifier", plan.identifier))(backupPlans)
  const hasChanged = !fastDeepEqual(plan, backupPlans[planIndex])

  if (hasChanged) {
    backupPlans[planIndex] = plan

    if (!isArrowImagePlan(backupPlans[planIndex].type) && backupPlans[planIndex].inheritance.inherited) {
      backupPlans[planIndex].inheritance.overridden = true
    }

    return {
      ...state,
      backupPlans,
    }
  }

  return state
}

export const onDeleteBackupPlan = (state, { plan }) => ({
  ...state,
  backupPlans: state.backupPlans.filter(({ identifier }) => identifier !== plan.identifier),
})

const onChangeEnabledStatus = (state, planId, enabled) => {
  const backupPlans = [...state.backupPlans]
  const planIndex = findIndex(propEq("identifier", planId))(backupPlans)
  backupPlans[planIndex].enabled = enabled
  if (backupPlans[planIndex].inheritance && backupPlans[planIndex].inheritance.inherited) {
    backupPlans[planIndex].inheritance.overridden = true
  }

  return {
    ...state,
    backupPlans,
  }
}

export const onEnableBackupPlan = (state, { planId }) => onChangeEnabledStatus(state, planId, true)

export const onDisableBackupPlan = (state, { planId }) => onChangeEnabledStatus(state, planId, false)

export const onRevertOverride = (state, { planId }) => {
  const backupPlans = [...state.backupPlans]
  const planIndex = findIndex(propEq("identifier", planId))(backupPlans)
  backupPlans[planIndex] = {
    ...backupPlans[planIndex],
    ...backupPlans[planIndex].inheritance.parentPolicySettings,
    inheritance: {
      ...backupPlans[planIndex].inheritance,
      overridden: false,
    },
  }

  return {
    ...state,
    backupPlans,
  }
}
