export const initialState = (state, backups = {}) => {
  return {
    ...state,
    backupPlans: [],
    //this will keep initial backup plans data
    storedBackupPlans: backups.backupPlans,
    general: {
      inheritance: {
        inherited: false,
        overridden: false,
        sourcePolicyId: null,
      },
    },
    ...backups,
  }
}
