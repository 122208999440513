/* eslint-disable jsx-a11y/media-has-caption */
import { useCallback, useRef, useMemo } from "react"
import Plyr from "plyr"
import PropTypes from "prop-types"
import "plyr/dist/plyr.css"

const MediaPlayer = ({
  defaultQuality = 720,
  onInitialPlay,
  poster,
  sources = [],
  title,
  tracks = [],
  type = "video",
}) => {
  const containerRef = useRef(null)
  const plyrInstanceRef = useRef(null)

  const qualityOptions = useMemo(() => {
    const sizes =
      sources
        ?.map(source => source.size)
        .filter((size, index, self) => typeof size === "number" && self.indexOf(size) === index)
        .sort((a, b) => a - b) ?? []
    return sizes
  }, [sources])

  const initializePlyr = useCallback(
    element => {
      if (element && !plyrInstanceRef.current) {
        plyrInstanceRef.current = new Plyr(element, {
          quality: {
            default: qualityOptions.includes(defaultQuality)
              ? defaultQuality
              : qualityOptions[qualityOptions.length - 1],
            options: qualityOptions,
          },
          settings: ["captions", "quality", "speed"],
        })

        plyrInstanceRef.current.source = {
          type,
          poster,
          sources,
          tracks,
        }

        plyrInstanceRef.current.once("ready", () => {
          if (containerRef.current) {
            const [mediaElement] = containerRef.current.getElementsByTagName(type)
            mediaElement.title = title
          }
        })

        plyrInstanceRef.current.once("playing", () => {
          onInitialPlay?.()
        })
      }
    },
    [defaultQuality, onInitialPlay, poster, qualityOptions, sources, title, tracks, type],
  )

  return (
    <div ref={containerRef}>
      <video crossOrigin="" ref={initializePlyr} />
    </div>
  )
}

MediaPlayer.propTypes = {
  defaultQuality: PropTypes.number,
  onInitialPlay: PropTypes.func,
  poster: PropTypes.string.isRequired,
  sources: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tracks: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["audio", "video"]),
}

export default MediaPlayer
