import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { groupBy, prop, toPairs } from "ramda"

import { matchSorter } from "match-sorter"

import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"

import {
  ComboboxProvider,
  Select,
  SelectPopover,
  SelectProvider,
  useSelectStore,
  useComboboxStore,
} from "@ariakit/react"

import tokens from "@ninjaone/tokens"
import { isRequiredIf, noop, useMountedState } from "@ninjaone/utils"

import { shouldIgnoreProps } from "./utils"

import {
  SINGLE_SELECT_DISPLAY_TYPE,
  selectTriggerMinHeight,
  popoverMaxHeight,
  popoverMinWidth,
  popoverMaxWidth,
  maxOptionsToHideCombobox,
  comboboxHeight,
} from "./constants"

import { Label } from "../Form/Label"

import { SelectValue } from "./Components/SelectValue"
import { SelectActions } from "./Components/SelectActions"
import { SelectCombobox } from "./Components/SelectCombobox"
import { SelectOptionsList } from "./Components/SelectOptionsList"

const StyledSelect = styled(Select, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  user-select: none;

  width: 100%;
  max-width: 100%;

  min-height: ${selectTriggerMinHeight};

  display: flex;
  align-items: center;
  gap: ${tokens.spacing[1]};
  justify-content: space-between;

  ${({ labelText }) => labelText && `margin-top: ${tokens.spacing[1]};`}

  padding: ${tokens.spacing[1]} ${tokens.spacing[3]};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackground};

  border: 1px solid ${({ theme, errorMessage, disabled }) => {
    return errorMessage && !disabled ? theme.colorTextDanger : theme.colorBorderWeak
  }};

  &:not([aria-disabled="true"]) {
    cursor: pointer;
  }

  &[aria-disabled="true"] {
    opacity: 1;
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

const StyledSelectPopover = styled(SelectPopover)`
  overflow-y: auto;
  overflow-x: hidden;

  z-index: 100;

  border-radius: ${tokens.borderRadius[1]};

  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  background-color: ${({ theme }) => theme.colorBackground};

  scroll-padding-top: ${comboboxHeight};

  min-width: ${popoverMinWidth};
  max-height: ${popoverMaxHeight};

  ${({ width, sameWidth }) => !sameWidth && `width: ${width || "auto"}; max-width: ${popoverMaxWidth};`}
`

function SearchableSelect({
  comboboxProps,
  defaultValue,
  disabled,
  groupKey,
  isMulti,
  labelText,
  loading,
  onChange,
  options,
  placeholderText,
  selectedValueDisplay,
  value: controlledValue,
  errorMessage,
  required,
  tooltipText,
  ariaLabel,
  popoverProps,
  onScrollEnd,
}) {
  const [searchValue, setSearchValue] = useMountedState("")

  const ariaId = useMemo(() => {
    return uuidv4()
  }, [])

  const matchesOptions = useMemo(() => {
    const items = matchSorter(options, searchValue, { keys: ["labelText"], baseSort: noop })

    if (!!groupKey) {
      return toPairs(groupBy(prop(groupKey), items))
    }

    return items
  }, [options, searchValue, groupKey])

  const handleOnChangeSearchValue = searchValue => {
    setSearchValue(searchValue)
    comboboxProps?.onValueChange?.(searchValue)
  }

  const comboboxStore = useComboboxStore({
    value: searchValue,
    setValue: handleOnChangeSearchValue,
    resetValueOnHide: comboboxProps?.resetValueOnHide,
  })

  const selectStore = useSelectStore({
    items: options,
    setValue: onChange,
    combobox: comboboxStore,
    open: popoverProps?.open,
    setOpen: popoverProps?.onOpenChange,
    ...(controlledValue ? { value: controlledValue } : { defaultValue: defaultValue || (isMulti ? [] : "") }),
  })

  /**
   * If you are using the loading prop, we'll assume that you are using a server-side search.
   * In this case, we'll always show the combobox, even if the server returns less items than the maxOptionsToHideCombobox.
   */
  const isComboboxVisible =
    typeof loading === "boolean" || comboboxProps?.creatable || options.length > maxOptionsToHideCombobox

  const isFullWidth = typeof popoverProps?.fullWidth === "undefined" || popoverProps?.fullWidth

  return (
    <ComboboxProvider {...{ store: comboboxStore }}>
      <SelectProvider {...{ store: selectStore }}>
        <div>
          {labelText && <Label {...{ forInputElement: false, labelText, id: ariaId, required, tooltipText }} />}

          <StyledSelect
            {...{
              labelText,
              disabled,
              render: <div />,
              errorMessage,
              focusable: true,
              "aria-busy": loading,
              "aria-required": required,
              accessibleWhenDisabled: true,
              "aria-invalid": !!errorMessage,
              "data-ninja-searchable-select": "",
              ...(!labelText ? { "aria-label": ariaLabel } : { "aria-labelledby": ariaId }),
            }}
          >
            <SelectValue
              {...{
                isMulti,
                disabled,
                placeholderText,
                selectedValueDisplay: selectedValueDisplay,
              }}
            />

            <SelectActions {...{ ariaId, errorMessage, disabled }} />
          </StyledSelect>

          <StyledSelectPopover
            {...{
              gutter: 4,
              unmountOnHide: true,
              "aria-busy": loading,
              width: popoverProps?.width,
              portal: popoverProps?.portal,
              sameWidth: isFullWidth,
            }}
          >
            {isComboboxVisible && <SelectCombobox {...{ comboboxProps, options: matchesOptions }} />}

            <SelectOptionsList
              {...{
                options: matchesOptions,
                groupKey,
                isMulti,
                loading,
                creatable: comboboxProps?.creatable,
                sameWidth: isFullWidth,
                isComboboxVisible,
                noOptionsText: popoverProps?.noOptionsText,
                onScrollEnd,
              }}
            />
          </StyledSelectPopover>
        </div>
      </SelectProvider>
    </ComboboxProvider>
  )
}

SearchableSelect.defaultProps = {
  options: [],
}

SearchableSelect.propTypes = {
  /**
   * The ARIA label for accessibility.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.labelText,
    "ariaLabel is required when labelText is not provided",
  ),
  /**
   * Indicates if multiple selections are allowed.
   */
  isMulti: PropTypes.bool,
  /**
   * The key for the group of options.
   */
  groupKey: PropTypes.string,
  /**
   * Indicates if the select options are loading.
   */
  loading: PropTypes.bool,
  /**
   * Indicates if the select is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The label text for the select input.
   */
  labelText: PropTypes.string,
  /**
   * The controlled value of the select.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  /**
   * Sets the required state of the component.
   */
  required: PropTypes.bool,
  /**
   * Sets the error state of the component.
   */
  errorMessage: PropTypes.string,
  /**
   * The text for the Tooltip in the Label of the the component.
   */
  tooltipText: PropTypes.string,
  /**
   * The callback function when the value changes.
   */
  onChange: PropTypes.func,
  /**
   * The callback function when the scroll ends.
   */
  onScrollEnd: PropTypes.func,
  /**
   * The array of options for the select.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The label text for the option.
       */
      labelText: PropTypes.string,
      /**
       * The description text for the option.
       */
      descriptionText: PropTypes.string,
      /**
       * The value of the option.
       */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * Indicates if the option is disabled.
       */
      disabled: PropTypes.bool,
      /**
       * The icon for the option.
       */
      icon: PropTypes.node,
      /**
       * The description for the option.
       */
      description: PropTypes.string,
    }),
  ).isRequired,
  /**
   * The default value of the select input.
   */
  defaultValue: PropTypes.any,
  /**
   * The placeholder text for the select input.
   */
  placeholderText: PropTypes.string,
  /**
   * The display type of the selected value. The SINGLE_SELECT_DISPLAY_TYPE.ICON should only be used internally.
   */
  selectedValueDisplay: PropTypes.oneOf([SINGLE_SELECT_DISPLAY_TYPE.ICON]),
  /**
   * The props for the combobox.
   */
  comboboxProps: PropTypes.shape({
    /**
     * Indicates if the combobox value should be reset when the popover is hidden.
     */
    resetValueOnHide: PropTypes.bool,
    /**
     * Indicates if the combobox is creatable.
     */
    creatable: PropTypes.bool,
    /**
     * The callback function when the create button is clicked.
     */
    onCreate: PropTypes.func,
    /**
     * Indicates if the combobox is disabled.
     */
    disabled: PropTypes.bool,
    /**
     * The callback function when the combobox value changes.
     */
    onValueChange: PropTypes.func,
  }),
  /**
   * The props for the popover.
   */
  popoverProps: PropTypes.shape({
    /**
     * Indicates if the popover should be rendered as a portal.
     */
    portal: PropTypes.bool,
    /**
     * Indicates if the popover is open. This is used for controlled popover visibility.
     */
    open: PropTypes.bool,
    /**
     * The callback function when the popover visibility changes.
     */
    onOpenChange: PropTypes.func,
    /**
     * Indicates if the popover should be have the same width as the select.
     */
    fullWidth: PropTypes.bool,
    /**
     * The width of the popover. This property is only used when `fullWidth` is set to false.
     * If the `width` prop is not specified, the popover will expand to the width of the widest option, respecting the max-width.
     */
    width: PropTypes.string,
    /**
     * Localized text to display when the select has no options. Defaults to "No options found" or "No match found" for creatable selects.
     */
    noOptionsText: PropTypes.string,
  }),
}

export default SearchableSelect
